


























































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { isEmpty } from "lodash";
import { mapActions, mapGetters } from "vuex";
import DeleteBtn from "@monorepo/uikit/src/components/tableViews/DeleteBtn.vue";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import ExportBtn from "@monorepo/uikit/src/components/tableViews/ExportBtn.vue";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import ContractsTable from "@monorepo/catalog/src/views/OikView/components/ContractsTable.vue";
import Nomenclature from "@monorepo/catalog/src/views/OikView/components/Nomenclature.vue";
import HandoverSchedule from "@monorepo/catalog/src/views/OikView/components/HandoverSchedule.vue";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import { IOikArchiveElement, IOikCardElement } from "@monorepo/catalog/src/views/OikView/types/oikCardElement";
import SelectRolesCard from "@monorepo/uikit/src/components/common/Select/SelectRolesCard.vue";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/oikView/viewTitle";
import {
  baseModalElements,
  fullModalElements as modalElements,
  medoElements,
  techElements,
} from "@monorepo/utils/src/variables/projectsData/oikView/modalElements";
import {
  convertSaveCardObject,
  convertSaveContracts,
  convertOikSaveCardObject,
} from "@monorepo/catalog/src/views/OikView/utils/convertSaveCardObject";
import { convertStatus } from "@monorepo/catalog/src/views/ArchiveView/utils/convertApiItemToUi";
import { IOikContractDto } from "@monorepo/catalog/src/views/OikView/types/oikElement";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";
import { fields, IOikFields } from "@monorepo/utils/src/variables/projectsData/oikView/fields";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import useMoveByAuthorities from "@monorepo/utils/src/composables/useMoveByAuthorities";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import eventBus from "@monorepo/utils/src/eventBus/index";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { IArchiveHierarchyElement } from "@monorepo/catalog/src/store/modules/archiveCatalogView";
import { defaultFilterPlaceholder } from "@monorepo/utils/src/constants";
import Autocomplete from "@monorepo/uikit/src/components/tableViews/Autocomplete.vue";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import { SyncStatuses, SyncStatusValues } from "@monorepo/catalog/src/views/OikView/types/syncStatuses";
import { Route } from "vue-router";
import { debounce } from "lodash";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import FormDatePicker from "@monorepo/uikit/src/components/tableCardModal/FormDatePicker.vue";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import { validateEmail } from "@monorepo/utils/src/api/utils";
import useCheckChangesModal from "@monorepo/utils/src/composables/useCheckChangesModal";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";

export default defineComponent({
  name: "InventoryInfoModal",
  components: {
    CardModalInfoElement,
    FormInputElement,
    ContractsTable,
    HandoverSchedule,
    Nomenclature,
    CardModalStatusChip,
    Autocomplete,
    CardModalToggleViewSize,
    ExportBtn,
    DeleteBtn,
    CardModalToggleTableItems,
    SelectRolesCard,
    FormDatePicker,
    CardModalChapter,
  },
  props: {
    type: {
      type: String,
      default: ModalType.NEW,
    },
  },
  data(): {
    ModalType: typeof ModalType;
    SyncStatuses: typeof SyncStatuses;
    SyncStatusValues: typeof SyncStatusValues;
    key: string;
    viewUniqKey: string;
    isOpenByTemplate: boolean;
    isSaveLoading: boolean;
    modalElements: IModalElementType[];
    baseModalElements: IModalElementType[];
    medoElements: IModalElementType[];
    techElements: IModalElementType[];
    contracts: IOikContractDto[];
    openedPanels: number[];
    isLoading: boolean;
    archiveTreeIcon: string;
    attachArrow: string;
    fields: IOikFields;
    documents: IArchiveHierarchyElement[];
    defaultFilterPlaceholder: string;
    isLoadingAutocomplete: boolean;
    isLoadingSync: boolean;
    isOpenedNomenclature: boolean;
    isDisableSync: boolean;
    isArchiveFocused: boolean;
    cardMode: typeof CardMode;
    section: Sections;
    startDate: string;
    endDate: string;
  } {
    return {
      ModalType,

      SyncStatuses,
      medoElements,
      isSaveLoading: false,
      startDate: "",
      endDate: "",
      SyncStatusValues,
      cardMode: CardMode,
      isOpenByTemplate: false,
      section: Sections.OIK,
      attachArrow: `${environmentVariables.BASE_URL}assets/images/oik/attachArrow.svg`,
      archiveTreeIcon: `${environmentVariables.BASE_URL}assets/images/oik/archiveTreeIcon.svg`,
      key: uuid(),
      viewUniqKey,
      modalElements,
      baseModalElements,
      techElements,
      contracts: [],
      openedPanels: [0, 1, 2, 3],
      isLoading: false,
      isOpenedNomenclature: false,
      fields,
      documents: [],
      defaultFilterPlaceholder,
      isLoadingAutocomplete: false,
      isLoadingSync: false,
      isDisableSync: false,
      isArchiveFocused: false,
    };
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          addQueryOpenedId: () => void;
          getResultSaveData: (data: Partial<IOikCardElement>) => Partial<IOikCardElement>;
          formValues: Partial<IOikCardElement>;
          isOpenByTemplate: boolean;
          changedFormValues: Partial<IOikCardElement>;
          $route: Route;
          isLoading: boolean;
          refreshData: (oikId: string) => void;
          startDate: string;
          endDate: string;
          handleDate: (element: string) => Promise<string>;
          currentYear: string;
        },
        value
      ) {
        if (this.isOpenByTemplate) {
          // Открытие по шаблону
          this.isOpenByTemplate = false;
          return;
        }

        if (value) {
          await this.addQueryOpenedId();
          await this.refreshData(value);
        } else {
          this.startDate = `${this.currentYear}-${(await this.handleDate("DEFAULT_START_TRANSFER_DATE"))?.split(".").reverse().join("-") || "01-01"}`;
          this.endDate = `${this.currentYear}-${(await this.handleDate("DEFAULT_END_TRANSFER_DATE"))?.split(".").reverse().join("-") || "12-31"}`;
          this.formValues = this.getResultSaveData({
            caseTransferStartDate: this.startDate,
            caseTransferEndDate: this.endDate,
            transferHistory: [
              {
                caseTransferStartDate: this.startDate,
                caseTransferEndDate: this.endDate,
              },
            ],
          });
          this.changedFormValues = this.getResultSaveData({
            caseTransferStartDate: this.startDate,
            caseTransferEndDate: this.endDate,
            transferHistory: [
              {
                caseTransferStartDate: this.startDate,
                caseTransferEndDate: this.endDate,
              },
            ],
          });
          this.isLoading = false;
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("oikCatalogView", ["data", "openedId", "fundsLib"]),
    isCanRefresh(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_LOG_UPDATE_MODIFY);
    },
    currentYear(): number {
      return new Date().getFullYear();
    },
    isEnableModifyGant(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_CASE_TRANSFER_MODIFY);
    },
    isShowGant(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_CASE_TRANSFER_LIST);
    },
    isShowNomenclature(): boolean {
      return isAuthorityExist(this.user, authorities.NOMENCLATURE_LIST);
    },
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    isArchiveEmpty(): boolean {
      return isEmpty((this.formValues as IOikCardElement)?.[fields.OIK_ARCHIVE as keyof IOikCardElement]);
    },
    syncIconSrc() {
      return (syncStatus: string) => {
        return syncStatus === SyncStatuses.PENDING
          ? `${environmentVariables.BASE_URL}assets/images/oik/pendingIcon.svg`
          : syncStatus === SyncStatuses.ERROR
          ? `${environmentVariables.BASE_URL}assets/images/oik/errorIcon.svg`
          : `${environmentVariables.BASE_URL}assets/images/oik/successIcon.svg`;
      };
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_EXPORT);
    },
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_CREATE);
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_MODIFY);
    },
    isShowDeleteBtn(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_DELETE);
    },
    isShowTechData(): boolean {
      return isAuthorityExist(this.user, authorities.TECH_INFO);
    },
    statusColor(): string {
      const status: string = (this.formValues as IOikCardElement)[fields.OIK_STATUS as keyof IOikCardElement] as string;
      switch (status) {
        case "Активный":
          return "#00A459";
        case "Удален":
          return "#D34039";
        default:
          return "";
      }
    },
    resultMedoElements(): IModalElementType[] {
      return medoElements.map((item) => {
        if (item.value !== fields.OIK_CLOUD_MEDO_GUID) {
          return item;
        }
        return {
          ...item,
          isEdited: !!(this.formValues as IOikCardElement)[fields.OIK_CLOUD_ARCHIVE as keyof IOikCardElement],
        };
      });
    },
    modalFieldTitle(): (field: string) => IModalElementType {
      return (field: string) => {
        return this.modalElements.find((item: IModalElementType) => item.value === field) || ({} as IModalElementType);
      };
    },
    title(): string {
      switch (this.type) {
        case ModalType.EDIT:
        case ModalType.READ:
          return (this.formValues as IOikCardElement).name as string;
        case ModalType.NEW:
        default:
          return "Новый ОИК";
      }
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow", "getNotificationSettings"]),
    ...mapActions("oikCatalogView", [
      "changeOpenedId",
      "saveContracts",
      "getOikCardElement",
      "getOikHierarchy",
      "getFundList",
      "restoreEntity",
      "saveCard",
      "refreshScroll",
      "deleteOik",
      "synchronizeOik",
    ]),
    changeNomenclature() {
      this.isOpenedNomenclature = !this.isOpenedNomenclature;
    },
    async handleDate(type: string) {
      const date: string = await this.getNotificationSettings(type);
      return date;
    },
    toggleArchiveFocus(value: boolean) {
      this.isArchiveFocused = value;
    },
    getTreeData: debounce(
      async function (
        this: {
          documents: IArchiveHierarchyElement[];
          getOikHierarchy: (archiveIds: (number | string)[]) => Promise<IArchiveHierarchyElement[]>;
        },
        formValues: Partial<IOikCardElement>
      ) {
        if (!((formValues[fields.OIK_ARCHIVE as keyof IOikCardElement] as IOikArchiveElement[]) || []).length) {
          this.documents = [];
          return;
        }
        const archiveData: IArchiveHierarchyElement[] = await this.getOikHierarchy(
          ((formValues[fields.OIK_ARCHIVE as keyof IOikCardElement] as IOikArchiveElement[]) || []).map((item: IOikArchiveElement) => item.id)
        );
        this.documents = [...archiveData] || [];
      },
      1000,
      { leading: false, trailing: true }
    ),
    async refreshData(oikId: string) {
      this.isLoading = true;
      let oikData: IOikCardElement = await this.getOikCardElement(oikId);
      if (oikData) {
        // this.getTreeData(oikData);
        const funds = oikData[fields.OIK_FUNDS as keyof IOikCardElement] as IOikArchiveElement[];
        let fundsResult: (IOikArchiveElement & { label: string })[] = [];
        if (funds?.length) {
          fundsResult = funds?.map((archive: IOikArchiveElement) => {
            return {
              ...archive,
              label: archive.shortName,
            };
          });
        } else {
          fundsResult = [];
        }
        const currentYearCaseTransfer = (oikData[fields.HANDOVER_HISTORY as keyof IOikCardElement] as Record<string, string>[]).find(
          (transferObj: Record<string, string>) => {
            return new Date(transferObj.caseTransferStartDate).getFullYear() === this.currentYear;
          }
        );

        this.$emit(
          "changeType",
          oikData.synchronizationStatus === SyncStatuses.PENDING
            ? ModalType.READ
            : this.user.authorities?.find((element: { authority: string }) => {
                return element.authority === authorities.OIK_MODIFY;
              }) && !oikData[fields.OIK_IS_DELETED as keyof IOikCardElement]
            ? ModalType.EDIT
            : ModalType.READ
        );

        this.formValues = {
          ...oikData,
          [fields.OIK_FUNDS]: fundsResult,
          [fields.OIK_STATUS]: convertStatus(!!oikData[fields.OIK_IS_DELETED as keyof IOikCardElement]),
          [fields.OIK_CREATION_DATE]: oikData[fields.OIK_CREATION_DATE as keyof IOikCardElement]
            ? moment(oikData?.[fields.OIK_CREATION_DATE as keyof IOikCardElement]?.toString() || "").format("YYYY-MM-DD HH:mm")
            : "",
          [fields.STATUS_DICT]: oikData[fields.STATUS_DICT as keyof IOikCardElement],
          [fields.OIK_UPDATE_DATE]: oikData[fields.OIK_UPDATE_DATE as keyof IOikCardElement]
            ? moment(oikData?.[fields.OIK_UPDATE_DATE as keyof IOikCardElement]?.toString() || "").format("YYYY-MM-DD HH:mm")
            : "",
          [fields.DATE_REFRESH_DICT]: oikData[fields.DATE_REFRESH_DICT as keyof IOikCardElement]
            ? moment(oikData?.[fields.DATE_REFRESH_DICT as keyof IOikCardElement]?.toString() || "").format("YYYY-MM-DD HH:mm")
            : "",
          [fields.LAST_DATE_REFRESH_DICT]: oikData[fields.LAST_DATE_REFRESH_DICT as keyof IOikCardElement]
            ? moment(oikData?.[fields.LAST_DATE_REFRESH_DICT as keyof IOikCardElement]?.toString() || "").format("YYYY-MM-DD HH:mm")
            : "",
          [fields.HANDOVER_DATE_FROM]: currentYearCaseTransfer?.caseTransferStartDate
            ? moment(currentYearCaseTransfer.caseTransferStartDate).format("YYYY-MM-DD")
            : "",
          [fields.HANDOVER_DATE_TO]: currentYearCaseTransfer?.caseTransferEndDate
            ? moment(currentYearCaseTransfer.caseTransferEndDate).format("YYYY-MM-DD")
            : "",
          [fields.HANDOVER_HISTORY]: oikData[fields.HANDOVER_HISTORY as keyof IOikCardElement]
            ? (oikData[fields.HANDOVER_HISTORY as keyof IOikCardElement] as Record<string, string>[]).map((dates: Record<string, string>) => ({
                ...dates,
                year: new Date(dates.caseTransferStartDate).getFullYear().toString(),
              }))
            : [],
        };
        this.changedFormValues = this.getResultSaveData(this.formValues);
        this.isLoading = false;
      }
    },
    async synchronize(oikCode: string) {
      this.isLoadingSync = true;
      this.isDisableSync = true;
      const isSync = await this.synchronizeOik(oikCode);
      this.isLoadingSync = false;
      if (isSync) {
        showNotification("Запрос успешно отправлен", NOTIFICATION_STATUS.SUCCESS);
      }
    },
    openNewModal() {
      this.$emit("changeType", ModalType.NEW);
      this.$router.replace({ query: {} });
      const resultData = this.getResultSaveData(this.formValues);
      delete resultData.id;
      delete resultData.code;
      this.formValues = resultData as Record<string, string | boolean | IOikContractDto[]>;
      this.key = uuid();
      this.isOpenByTemplate = true;
    },
    openExport() {
      this.openNewWindow(getFullPath(QUERY_PATH.GET_CATALOG_OIK_PDF, "/", (this.formValues as IOikCardElement).id as string));
    },
    async deleteOikCb() {
      const isDeleted = await this.deleteOik({ ids: [(this.formValues as IOikCardElement).id] });
      if (isDeleted) {
        this.refreshScroll();
        this.closeModal();
      } else {
        showNotification("Ошибка удаления ОИК");
      }
    },
    openDeleteModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: this.deleteOikCb,
          title: `Удалить ОИК «${(this.formValues as IOikCardElement).name}»?`,
        })
      );
    },
    openRestoreModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: this.restoreOikCb,
          yesBtnTitle: "Да, восстановить",
          icon: "mdi-delete-restore",
          title: `Восстановить ОИК «${(this.formValues as IOikCardElement).name}»?`,
        })
      );
    },
    async restoreOikCb() {
      const isDeleted = await this.restoreEntity((this.formValues as IOikCardElement).id);
      if (isDeleted) {
        this.refreshScroll();
        this.closeModal();
      } else {
        showNotification("Ошибка восстановления ОИК");
      }
    },
    getResultSaveData(data: Partial<IOikCardElement>) {
      const currentYearTransfer = data.transferHistory?.find(
        (item) => new Date(item.caseTransferStartDate).getFullYear() === new Date().getFullYear()
      );
      let resultTransferHistory;
      if (currentYearTransfer) {
        resultTransferHistory =
          data.transferHistory?.map((item) => {
            if (item.id === currentYearTransfer.id) {
              return {
                ...(item.id ? { id: item.id } : {}),
                caseTransferStartDate: data.caseTransferStartDate || "",
                caseTransferEndDate: data.caseTransferEndDate || "",
              };
            }
            return item;
          }) || [];
      } else {
        resultTransferHistory = (data.transferHistory || []).concat({
          caseTransferStartDate: data.caseTransferStartDate || "",
          caseTransferEndDate: data.caseTransferEndDate || "",
        });
      }
      const { cloudMedoGuid, ...result } = data;

      const resultData: Partial<IOikCardElement> = {
        ...result,
        id: result.id || (this.formValues as IOikCardElement).id || undefined,
        transferHistory: resultTransferHistory,
        ...(cloudMedoGuid ? { cloudMedoGuid } : {}),
      };
      return convertSaveCardObject(resultData);
    },
    async onSave(data: Record<string, string | boolean>) {
      const isNeedSave = (this as unknown as { checkChangesBeforeSave: () => boolean }).checkChangesBeforeSave();
      if (isNeedSave) {
        const resultData = this.getResultSaveData(data);
        const contracts = Array.isArray((this.formValues as IOikCardElement).oikContracts)
          ? convertSaveContracts((this.formValues as IOikCardElement).oikContracts)
          : [];
        let isSavedContracts = true;
        if (!isEmpty(contracts)) {
          isSavedContracts = await this.saveContracts(contracts);
        }

        const email = resultData.contactEmail;

        if (email && !validateEmail(email)) {
          showNotification("Проверьте правильность заполнения Email");
          return;
        }

        if (resultData.medoGuid && resultData.medoGuid.includes("_")) {
          showNotification("Проверьте правильность заполнения GUID ОИК в МЭДО");
          return;
        }

        this.isSaveLoading = true;
        const isSavedItem = await this.saveCard(resultData).finally(() => {
          this.isSaveLoading = false;
        });

        if (isSavedItem && isSavedContracts) {
          this.changedFormValues = resultData;
          showNotification("Данные успешно сохранены.", NOTIFICATION_STATUS.SUCCESS);
          this.refreshScroll();
          this.closeModal();
        }
      } else {
        return;
      }
    },
    changeContracts(contracts: IOikContractDto[]) {
      (this.formValues as IOikCardElement).oikContracts = contracts;
    },
    closeModal(): void {
      if (this.type !== ModalType.READ) {
        const isClose = (this as unknown as { checkChangesBeforeClose: () => boolean }).checkChangesBeforeClose();
        if (isClose) {
          return void this.$emit("close");
        }
        return;
      }
      this.$emit("close");
    },
    clickElementCb(key: string, item: Record<string, unknown>) {
      switch (key) {
        case "fund":
        case "archive":
          this.$router.push({
            path: `/dictionaries/${key}`,
            query: {
              name: (item.name as string) || "",
              id: (item.id as string) || "",
              isOpenModal: "1",
            },
          });
          break;
        default:
          break;
      }
    },
  },
  setup(props, context) {
    const { root } = context;
    const formValues = ref({});
    const changedFormValues = ref({});
    const { checkChangesBeforeClose, checkChangesBeforeSave } = useCheckChangesModal(
      context,
      formValues,
      changedFormValues,
      convertOikSaveCardObject
    );
    const moveByAuthorities = useMoveByAuthorities(root);
    useModalChangeByKeypress(root, "oikCatalogView", Sections.OIK, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "oikCatalogView");

    return {
      formValues,
      changedFormValues,
      addQueryOpenedId,
      moveByAuthorities,
      checkChangesBeforeClose,
      checkChangesBeforeSave,
    };
  },
});
